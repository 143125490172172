<template>
  <div class="area_list">
    <div class="head_tbl">
      <strong class="screen_out">결과 목록</strong>
      <div class="wrap_btn">
        <div class="pos_right">
          <!--읽음/안읽음-->
          <button
            v-if="!isTempDocs"
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="toggleReadYn"
          >
            {{ readYn }}
          </button>
          <button
            v-else
            type="button"
            class="btn_medium btn_secondary"
            @click.prevent="deleteConfirm"
          >
            삭제
          </button>
        </div>
      </div>
    </div>
    <!-- // head_tbl -->
    <div class="tbl_comm tbl_list">
      <table>
        <caption class="ir_caption">
          결과 목록 표
        </caption>
        <colgroup>
          <col v-for="item in listLineDatas" :key="item.key" :style="item.colStyle" />
        </colgroup>
        <thead>
          <tr>
            <th v-for="item in listLineDatas" :key="item.key" scope="col">
              <div v-if="item.key === 'checkbox'" class="box_choice type_alone">
                <!-- 텍스트 없이 아이콘만 있는 타입 type_alone 클래스 추가 -->
                <div class="item_choice">
                  <!-- disabled 상태시 disabled 클래스 추가 -->
                  <input
                    id="checkLineAll"
                    v-model="checkAll"
                    type="checkbox"
                    class="inp_choice"
                    name="checkLineAll"
                    @change="toggleAll"
                  />
                  <label class="lab_choice" for="checkLineAll">
                    <span class="ico_account ico_check" />
                  </label>
                </div>
              </div>
              <template v-else>
                {{ item.text }}
              </template>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="empty">
            <td :colspan="lengthColumn">조회된 문서가 없습니다.</td>
          </tr>
          <tr
            v-for="document in docList"
            :key="document.docNo"
            :class="[
              document.prev_read,
              {
                tr_select: checkedDocs.find((item) => item == document.docNo),
              },
            ]"
          >
            <td
              v-for="item in listLineDatas"
              :key="item.key"
              :class="item.class"
              :title="item.class && item.class.indexOf('td_ellip') > -1 ? document[item.key] : ''"
            >
              <template v-if="item.key === 'checkbox'">
                <!--체크 박스-->
                <div class="box_choice type_alone">
                  <div class="item_choice">
                    <input
                      :id="document.docNo"
                      v-model="checkedDocs"
                      type="checkbox"
                      class="inp_choice"
                      name="checkLine"
                      :value="document.docNo"
                    />
                    <label class="lab_choice" :for="document.docNo">
                      <span class="ico_account ico_check" />
                    </label>
                  </div>
                </div>
              </template>
              <template v-else-if="item.key === 'docNo'">
                <!--문서번호-->
                {{ document.docNo }}
              </template>
              <template v-else-if="item.key === 'targetGubun'">
                <!--문서 구분-->
                <button class="link_detail align_center" @click="openDetailePage(document)">
                  {{ document.targetGubunName }}
                </button>
              </template>
              <template v-else-if="item.key === 'docTitle'">
                <!-- 문서제목 -->
                <router-link
                  :to="toPath(document.docNo, docFlag)"
                  class="link_detail"
                  v-html="document.docTitle"
                >
                </router-link>
              </template>
              <template v-else-if="item.key === 'drftAccountId'">
                <!--기안요청자-->
                {{ `${document.drftEmpName} (${document[item.key]})` }}
              </template>
              <template v-else-if="item.key === 'nextAprvlAccountId'">
                <!--기안요청자-->
                {{ `${document.nextAprvlEmpName}`
                }}{{ document.nextAprvlEmpName ? ` (${document[item.key]})` : "-" }}
              </template>
              <template v-else-if="item.key === 'docStatusDate'">
                <!--상태변경일-->
                <span class="td_break">{{ document.docStatusDate | dateTimeStringFormat }}</span>
              </template>
              <template v-else-if="item.key === 'drftDate'">
                <!--상신일 등록일-->
                <span class="td_break">{{ document.drftDate | dateTimeStringFormat }}</span>
              </template>

              <template v-else-if="item.key === 'regDate'">
                <!--문서 등록일-->
                <span class="td_break">{{ document.regDate | dateTimeStringFormat }}</span>
              </template>

              <template v-else>
                {{ document[item.key] }}
              </template>
            </td>
            <!-- <td>

            </td>
            <td>{{ document.pdfHistoryResult.menuId }}</td>
            <td>{{ document.pdfHistoryResult.reqTitle }}</td>
            <td>{{ document.drftAccountId }} ({{ document.drftEmpName }})</td>
            <td>{{ document.nextAprvlAccountId }} ({{ document.nextAprvlEmpName }})</td>
            <td>{{ document.regDate }}</td> -->
            <!-- <td>
              <a
                v-else-if="item.key === 'title'"
                href="javascript:void(0);"
                class="link_detail"
                @click.prevent="docDetail(document)"
                >{{ unescape(document.title) }}</a
              >내용 셀은 link_detail 구조로 래핑
            </td> -->
            <!-- <td>
              <div class="box_choice type_alone">
                <div class="item_choice">
                  <input
                    type="checkbox"
                    :id="document.docNo"
                    class="inp_choice"
                    name="checkLine"
                    :value="document.docNo"
                    v-model="checkedDocs"
                  />
                  <label class="lab_choice" :for="document.docNo">
                    <span class="ico_account ico_check"></span>
                  </label>
                </div>
              </div>
            </td>
            <td v-if="openDocNo">{{ document.docNo }}</td>
            <td v-if="openConfirmRef">
              {{ getDocStateText(document.docState) }}
            </td>
            <td>{{ document.payAccountName }}</td>
            <td class="cell_left">
              <a
                href="javascript:void(0);"
                class="link_detail"
                @click.prevent="docDetail(document)"
                >{{ document.subject }}</a
              >
            </td>
            <td v-if="isApproval">
              {{ document.draftAccountId }} ({{ document.draftEmpName }})
            </td>
            <td v-if="isApproval">{{ document.draftDeptName }}</td>
            <td v-if="openApprovalLine">
              {{ document.nextApprAccountId }} ({{ document.nextApprEmpName }})
            </td>
            <td v-if="openDraftDate">{{ document.draftDatetime }}</td>
            <td v-if="openTempDate">{{ document.insertDatetime }}</td>
            <td v-if="openCompleteDate">{{ document.completeDatetime }}</td>
            <td v-if="openRejectDate">{{ document.completeDatetime }}</td>
            <td v-if="openPublic">
              {{ document.empCorpCardOpenYn == 'Y' ? '공개' : '비공개' }}
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <!-- // tbl_comm -->
    <div
      class="paging_comm"
      :class="docList && (!docList.length || docList.length === 0) ? 'paging_none' : ''"
    >
      <paginate
        v-model="paginateConfig.pageCurrent"
        :click-handler="setPaging"
        :container-class="paginateConfig.ulClass"
        :prev-text="paginateConfig.prevText"
        :next-text="paginateConfig.nextText"
        :page-range="paginateConfig.pageRange"
        :margin-pages="paginateConfig.marginPage"
        :page-count="paginateConfig.pageCount || 0"
      />
    </div>
  </div>
</template>

<script>
import { default as CommPaginateMixin } from "@/_approval/mixins/common/commPaginate.js";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommToastMixin } from "@/_approval/mixins/common/commToast.js";
import axios from "axios";
import { targetGubun } from "@/_approval/const/const";
import ApiService from "@/services/ApiService";
import commDocs from "@/_approval/mixins/common/commDocs";

import ListPage from "@/components/shared/ListPage";
import { makePathWithQuery } from "@/utils/urlUtils";

let initSort = {
  sortField: "COMPLETE_DATE",
  sortFlagDraft: "DESC",
  sortFlagComplete: "DESC",
  sortOnDraft: "",
  sortOnComplete: "",
};

export default {
  name: "ListDiv",
  components: {
    ListPage,
  },
  mixins: [CommPaginateMixin, CommLayerMixin, CommToastMixin, commDocs],
  props: {
    isTempDocs: Boolean,
    openDocNo: Boolean, // 구매요청번호
    openConfirmRef: Boolean, // 상태
    openDraftDate: Boolean, // 신청일시
    openTempDate: Boolean, // 지정일시
    openCompleteDate: Boolean, // 승인일시.
    openRejectDate: Boolean, // 반려 일시.
    openApprovalLine: Boolean, // 결재 대기자.
    openPublic: Boolean, // 공개비공개
    isApproval: Boolean, // 신청자, 신청부서 관련.
    // isConfirm: Boolean,

    listLineDatas: Array,

    totalPage: Number,
    docList: Array,
    docStatus: String,
    aprvlStatus: String,
    docFlag: String,
  },
  data() {
    return {
      currentPage: localStorage.pageNo ? localStorage.pageNo : 1, // 처음에 페이지 정보가 있는 경우 해당 페이지에 대한 리스트를 내보낸다
      countPerPage: 10,
      sort: { ...initSort },
      empty: false,
      prevDisable: false,
      nextDisable: false,
      checkedDocs: [],
      totalDocCount: 0,
      checkAll: false,
      readYn: "읽음",
      path: "",
    };
  },
  computed: {
    lengthColumn: function () {
      return this.listLineDatas ? this.listLineDatas.length : 0;
      // return (
      //   12 -
      //   (this.openDocNo ? 0 : 1) -
      //   (this.openDraftDate || this.openTempDate ? 0 : 1) -
      //   (this.isApproval ? 0 : 2) -
      //   (this.openApprovalLine ? 0 : 1) -
      //   (this.openCompleteDate || this.openRejectDate ? 0 : 1) -
      //   (this.openPublic ? 0 : 1) -
      //   2
      // );
    },
  },
  watch: {
    checkedDocs(checkedDocs) {
      if (checkedDocs && checkedDocs.length !== 0 && checkedDocs.length === this.totalDocCount) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }

      if (this.isTempDocs || checkedDocs.length === 0) {
        this.readYn = "읽음";
        return;
      }

      const existNoReadDoc = checkedDocs
        .map((pk) => this.docList.find((e) => e.docNo === pk))
        .some((e) => {
          return e.readDate == null;
        });

      this.readYn = existNoReadDoc ? "읽음" : "안읽음";
    },
    docList(docList) {
      this.totalDocCount = this.docList ? this.docList.length : 0;
      this.paginateConfig.pageCount = this.totalPage;
      if (this.docList && this.docList.length > 0) {
        this.docList.forEach((doc) => {
          switch (doc.draftFlag) {
            case "1":
              doc.draftFlagName = "일반";
              break;
            case "2":
              doc.draftFlagName = "법인카드";
              break;
            case "3":
              doc.draftFlagName = "Invoice";
              break;
          }
          if (doc.readYn === "N") {
            doc.prev_read = "prev_read";
          }
        });
        if (this.currentPage > 1) this.prevDisable = true;
        else this.prevDisable = false;
        if (this.currentPage < this.totalPage) this.nextDisable = true;
        else this.nextDisable = false;
        this.pagination = [];
        for (let i = -1; i <= 1; i++) {
          let isActive = false;
          let page = this.paginateConfig.pageCurrent + i;
          if (page === this.currentPage) {
            isActive = true;
          }
          if (page > 0 && page <= this.totalPage) {
            this.pagination.push({
              page: page,
              isActive: isActive,
            });
          }
        }
        this.empty = false;
      } else {
        this.empty = true;
      }
      this.checkedDocs.splice(0);
    },
  },
  created() {
    if (this.openCompleteDate || this.openRejectDate) {
      initSort.sortField = "COMPLETE_DATE";
      this.sort.sortField = "COMPLETE_DATE";
    } else {
      initSort.sortField = "DRAFT_DATE";
      this.sort.sortField = "DRAFT_DATE";
    }
  },
  mounted() {
    this.resetSort(this.currentPage);
  },
  methods: {
    toPath(docNo, docFlag) {
      switch (docFlag) {
        // ----- 내 문서함
        // 내 문서함 > 잔행중문서함
        case "DI":
          this.path = this.$paymentRouterPath.PAYMENT_DRAFT_ING_DETAIL;
          return makePathWithQuery(this.path, docNo, this.$route.query);

        // 내 문서함 > 완료된문서함
        case "DC":
          this.path = this.$paymentRouterPath.PAYMENT_DRAFT_COMPLETE_DETAIL;
          return makePathWithQuery(this.path, docNo, this.$route.query);

        // 내 문서함 > 반려된문서함
        case "DR":
          this.path = this.$paymentRouterPath.PAYMENT_DRAFT_REJECT_DETAIL;
          return makePathWithQuery(this.path, docNo, this.$route.query);

        // ----- 내 결제함
        // 내 결제함 > 결제전문서함
        case "AB":
          this.path = this.$paymentRouterPath.PAYMENT_APPROVAL_BEFORE_DETAIL;
          return makePathWithQuery(this.path, docNo, this.$route.query);

        // 내 결제함 > 승인할문서함
        case "AE":
          this.path = this.$paymentRouterPath.PAYMENT_APPROVAL_RECEIVE_DETAIL;
          return makePathWithQuery(this.path, docNo, this.$route.query);

        /* 아래부터 확인필요 */
        // 내 결제함 > 승인한문서함
        case "AI":
          this.path = this.$paymentRouterPath.PAYMENT_APPROVAL_ING_DETAIL;
          return makePathWithQuery(this.path, docNo, this.$route.query);

        // 내 결제함 > 완료된문서함
        case "AC":
          this.path = this.$paymentRouterPath.PAYMENT_APPROVAL_COMPLETE_DETAIL;
          return makePathWithQuery(this.path, docNo, this.$route.query);

        // 내 결제함 > 반려한문서함
        case "AR":
          this.path = this.$paymentRouterPath.PAYMENT_APPROVAL_REJECT_DETAIL;
          return makePathWithQuery(this.path, docNo, this.$route.query);
        case "RI":
          this.path = this.$paymentRouterPath.PAYMENT_CC_ING_DETAIL;

          return makePathWithQuery(this.path, docNo, this.$route.query);
        case "RC":
          this.path = this.$paymentRouterPath.PAYMENT_CC_COMPLETE_DETAIL;
          return makePathWithQuery(this.path, docNo, this.$route.query);
      }

      // 내 결제함 > 저장문서함
      this.path = this.$paymentRouterPath.PAYMENT_DRAFT_TEMP;
      return makePathWithQuery(this.path, docNo, this.$route.query);
    },
    setPaging(page) {
      this.paginateConfig.pageCurrent = Number(page); // 선택한 페이지 번호 값 세팅 (필수)
      this.selectDocList();
    },
    selectDocList() {
      this.$emit("get-doc-list", this.paginateConfig, this.sort);
    },
    async toggleReadYn() {
      const targetPKs = this.checkedDocs;
      const existNoReadDoc = targetPKs
        .map((pk) => this.docList.find((e) => e.docNo === pk))
        .some((e) => e.readDate == null);

      if (_.isEmpty(targetPKs)) {
        this._showLayer({ type: "alert", content: "선택된 문서가 없습니다." });
        return;
      }

      const params = {
        targetGubun: targetGubun.STANDARD,
        docNoList: [...this.checkedDocs],
        readYn: existNoReadDoc,
      };

      // if (this.docStatus) { params.docStatus = this.docStatus; }
      // if (this.aprvlStatus) { params.aprvlStatus = this.aprvlStatus; }

      const { docStatus, aprvlStatus } = this.docFlagToCode(this.docFlag);
      params.docStatus = docStatus;
      params.aprvlStatus = aprvlStatus;

      const res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_READ, params);

      if (this.checkNormalResponse(res)) {
        this.resetSort(this.currentPage);
      }
    },
    updateReadYn(data) {
      axios
        .post("/api/updateDocumentRead", data)
        .then((res) => {
          this.setPaging(this.paginateConfig.pageCurrent);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteConfirm() {
      if (!this.checkedDocs || this.checkedDocs.length === 0) {
        this._showLayer({ type: "alert", content: "선택된 문서가 없습니다." });
        return;
      }

      this._showLayer(
        { content: "삭제를 진행하시겠습니까?", type: "confirm" },
        this.deleteTempDocs,
      );
    },
    async deleteTempDocs() {
      const data = {
        targetGubun: targetGubun.STANDARD,
        docNoList: this.checkedDocs,
      };
      const res = await ApiService.shared.delete(this.$apiPath.APRVL_DOC_LIST, {
        data,
      });

      if (!this.checkNormalResponse(res)) {
        return;
      }

      this._showToast({ content: "삭제되었습니다." });
      this.setPaging(this.paginateConfig.pageCurrent);
    },
    docDetail(document) {
      this.$emit("go-detail", document.docNo);
    },
    onSort(sortField) {
      if (sortField === "DRAFT_DATE") {
        if (this.sort.sortField !== sortField) {
          this.sort.sortField = sortField;
          this.sort.sortOnDraft = "";
          this.sort.sortFlagDraft = "DESC";
        } else {
          if (this.sort.sortFlagDraft === "DESC") {
            this.sort.sortOnDraft = "sort_on";
            this.sort.sortFlagDraft = "ASC";
          } else {
            this.sort.sortOnDraft = "";
            this.sort.sortFlagDraft = "DESC";
          }
        }
        this.sort.sortOnComplete = "";
        this.sort.sortFlagComplete = "DESC";
      } else {
        if (this.sort.sortField !== sortField) {
          this.sort.sortField = sortField;
          this.sort.sortOnComplete = "";
          this.sort.sortFlagComplete = "DESC";
        } else {
          if (this.sort.sortFlagComplete === "DESC") {
            this.sort.sortOnComplete = "sort_on";
            this.sort.sortFlagComplete = "ASC";
          } else {
            this.sort.sortOnComplete = "";
            this.sort.sortFlagComplete = "DESC";
          }
        }
        this.sort.sortOnDraft = "";
        this.sort.sortFlagDraft = "DESC";
      }
      this.setPaging(this.paginateConfig.pageCurrent);
    },
    toggleAll() {
      if (this.checkedDocs && this.checkedDocs.length === this.totalDocCount) {
        this.checkedDocs = [];
      } else {
        this.checkedDocs = [];
        this.docList.forEach((doc) => {
          this.checkedDocs.push(doc.docNo);
        });
      }
    },
    resetSort(page) {
      this.sort = { ...initSort };
      this.setPaging(page);
    },
    getDocStateText(docState) {
      if (docState === "A") {
        return "승인";
      } else if (docState === "R") {
        return "반려";
      } else {
        return "";
      }
    },
    docFlagToCode(flag) {
      // - 진행중 문서 : docStatus 'I'
      // - 완료된 문서 : docStatus 'C'
      // - 반려된 문서 : docStatus 'J'
      //
      // - 결재전 문서 : aprvlStatus 'W'
      // - 수신된 문서 : aprvlStatus 'R'
      // - 진행중 문서 : aprvlStatus 'A'
      // - 완료된 문서 : docStatus 'C', aprvlStatus 'ALL'
      // - 반려된 문서 : docStatus 'J', aprvlStatus 'ALL'
      //
      // - 진행중 문서 : docStatus 'I', aprvlStatus 'N'
      // - 처리된 문서 : docStatus 'C', aprvlStatus 'N'
      switch (flag) {
        case "DI":
          return { docStatus: "I", aprvlStatus: "" };
        case "DC":
          return { docStatus: "C", aprvlStatus: "" };
        case "DR":
          return { docStatus: "J", aprvlStatus: "" };
        case "AB":
          return { docStatus: "", aprvlStatus: "W" };
        case "AE":
          return { docStatus: "", aprvlStatus: "R" };
        case "AEW":
          return { docStatus: "", aprvlStatus: "R" };
        case "AI":
          return { docStatus: "", aprvlStatus: "A" };
        case "AC":
          return { docStatus: "C", aprvlStatus: "ALL" };
        case "AR":
          return { docStatus: "J", aprvlStatus: "ALL" };
        case "RI":
          return { docStatus: "I", aprvlStatus: "N" };
        case "RC":
          return { docStatus: "C", aprvlStatus: "N" };
      }
    },
    openDetailePage(document) {
      const { targetGubun, targetPk } = document;
      let path = "";
      switch (targetGubun) {
        case "STD": //기안
          path = this.$routerPath.DRAFT_VIEW;
          break;
        case "DC": //계약
          path = this.$routerPath.CONTRACT_VIEW;
          break;
        case "DO": //발주
          path = this.$routerPath.ORDER_VIEW;
          break;
        default:
          path = "";
          break;
      }
      if (path) {
        this.$windowOpen(`${path}/${targetPk}`);
      }
    },
  },
};
</script>
